import React from 'react';
import { Helmet } from 'react-helmet';

import 'modern-normalize';
import '../../styles/normalize';

import Header from './Header';
import LayoutRoot from './LayoutRoot';
import LayoutMain from './LayoutMain';

const TITLE = 'Teacher Portal';

const IndexLayout: React.FC = ({ children }) => (
  <LayoutRoot>
    <Helmet
      title={TITLE}
      link={[
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/icon?family=Material+Icons',
        },
      ]}
    />
    <Header title={TITLE} />
    <LayoutMain>{children}</LayoutMain>
  </LayoutRoot>
);

export default IndexLayout;
