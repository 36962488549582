import * as React from 'react';
import styled from '@emotion/styled';
import { transparentize } from 'polished';
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { heights, dimensions, colors } from '../../styles/variables';
import Container from './Container';
import { HeaderViewerQueryQuery } from '../../generated/graphql';
import { clearAuthToken } from '../../auth';
import { getCurrentPathname } from '../../util';
import { logger } from '../../logger';

const StyledHeader = styled.header`
  height: ${heights.header}px;
  padding: 0 ${dimensions.containerPadding}rem;
  background-color: ${colors.brand};
  color: ${transparentize(0.5, colors.white)};
`;

const HeaderInner = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;

const HomepageLink = styled(Link)`
  color: ${colors.white};
  font-size: 1.5rem;
  font-weight: 600;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const ActionLink = styled(Link)`
  color: ${colors.white};

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const ActionAnchor = styled.a`
  color: ${colors.white};
  display: inline-block;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const ActionLabel = styled.span`
  color: #ccc;
  display: inline-block;
`;

const Actions = styled.div`
  & > * {
    margin: 0 10px;
  }
`;

const headerQuery = gql`
  query headerViewerQuery {
    viewer {
      admin {
        id
        email
      }
    }
  }
`;

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const { loading, error, data } = useQuery<HeaderViewerQueryQuery>(
    headerQuery,
  );

  React.useEffect(() => {
    const admin = data?.viewer.admin;
    if (admin) {
      logger.identify({ email: admin.email, id: admin.id });
    }
  }, [data]);

  const onLogout = () => {
    clearAuthToken();
    logger.identify({ email: '', id: '' });
  };

  const renderActionsInner = () => {
    if (loading) return <p>loading...</p>;
    if (error) return <p>ERROR: {error.message}</p>;
    if (data && data.viewer.admin) {
      return (
        <>
          <ActionLabel>{data.viewer.admin.email}</ActionLabel>
          <ActionAnchor onClick={onLogout} href="/">
            Log out
          </ActionAnchor>
        </>
      );
    }
    return (
      <ActionLink to="/auth" state={{ redirect: getCurrentPathname() }}>
        Log In
      </ActionLink>
    );
  };

  return (
    <StyledHeader>
      <HeaderInner>
        <HomepageLink to="/">{title}</HomepageLink>
        <Actions>{renderActionsInner()}</Actions>
      </HeaderInner>
    </StyledHeader>
  );
};

export default Header;
